import React, { useState } from 'react';

import Columns from './columns';
import './contact-form.css';

function encode(data) {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
}

const ContactForm = () => {
    const [form, setForm] = useState({});
    const [doThank, setThank] = useState(false);

    const handleChange = ( e ) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handleSubmit = ( e ) => {
        e.preventDefault()
        const formElement = e.target;
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': formElement.getAttribute('name'),
                ...form,
            }),
        })
            .then(() => {
                formElement.reset();
                setThank(true);
            })
            .catch((error) => alert(error));
    };    

    return (
        <div className="contact-form">
            <Columns>
                <div style={{ textAlign: 'center' }}>
                    <h1 className="red">Contact</h1>
                    <h3>Seattle, WA, USA</h3>
                    <a href="mailto:emeraldlessley@gmail.com">emeraldlessley@gmail.com</a>
                </div>

                {doThank ? (
                    <div style={{ textAlign: 'center', margin: '5rem 5rem' }}>
                        <h1>Thank you.</h1>
                    </div>
                ) : (
                    <form
                        name="contact"
                        method="post"
                        action="/contact/thanks/"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        onSubmit={handleSubmit}
                    >
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <input style={{ flexGrow: 1 }} title="Name" type="text" name="name" placeholder="Name *" required onChange={handleChange} />
                            <input style={{ flexGrow: 1 }} title="Email" type="email" name="email" placeholder="Email *" required onChange={handleChange} />
                        </div>

                        <input title="Phone" type="tel" name="phone" placeholder="Phone" onChange={handleChange} />
                        <input title="Address" type="text" name="address" placeholder="Address" onChange={handleChange} />
                        <input message="Subject" type="text" name="subject" placeholder="Subject" onChange={handleChange} />
                        <textarea title="Message" name="message" required onChange={handleChange} placeholder="Message *"></textarea>

                        <button type="submit">Send</button>
                    </form>
                )}
            </Columns>
        </div>
    );
};

export default ContactForm;
