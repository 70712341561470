import React from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import house from '../images/house.jpg';
import headshot from '../images/headshot-index.jpg';
import curtain from '../images/curtain.jpg';
import aboutTheSingerHeadshot from '../images/about-the-singer-headshot.png';

import Layout from "../components/layout"
import SEO from "../components/seo";
import Columns from '../components/columns';
import EmeraldLessley from '../components/emerald';
import ContactForm from '../components/contact-form';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Carousel
      autoPlay
      infiniteLoop
      dynamicHeight={false}
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      interval={3000}
    >
      <div>
        <img src={headshot} alt="Emerald Lessley"/>
      </div>
      <div>
        <img src={house} alt="The house of an opera" />
      </div>
      <div>
        <img src={curtain} alt="Stage curtain" />
      </div>
    </Carousel>

    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '30em',
      margin: 'auto',
      marginTop: '5em',
      marginBottom: '5em',
    }}>
      <EmeraldLessley />
      <h3>American Soprano</h3>
    </div>

    <Columns>
      <img src={aboutTheSingerHeadshot} alt="Emerald Lessley" />
      <div className="maintain-padding copy">
        <h1 className="red">About the Singer</h1>
        <p>Emerald Lessley began studying music and performing at a young age in northern California. Dr. Lessley has enjoyed roles such as Fiordiligi in Mozart’s Così fan tutte, Eurydice in Glück’s Orphée et Eurydice, Donna Anna in Mozart's Don Giovanni, Suor Angelica in Puccini’s Suor Angelica, Calisto in Cavalli’s La Calisto, Semele in Handel’s Semele, Dido in Purcell’s Dido and Aeneas, Second Lady in Mozart’s Die Zauberflöte, Gasparina in Haydn’s La Canterina, Geraldine in Barber’s A Hand of Bridge, La Ciesca in Puccini’s Gianni Schicchi, Pauline in Barab’s A Toy Shop, Estrella in Offenbach’s La Perichole, and Belisa in the U.S. premiere of Maderna’s opera Don Perlimplìn. In addition to traditional operatic repertoire, Emerald is an active performer of new music. She loves premiering new works and has enjoyed recent collaborations with composers on concerts in both the U.S. and Europe.</p>
      </div>
    </Columns>

    <ContactForm />
  </Layout>
)

export default IndexPage
