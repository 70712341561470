import React from 'react';
import './columns.css';

const Columns = ({ children }) => 
    <div className="columns">
        {children.map( ( child, idx ) => (
            <div className="col" style={{
                paddingLeft: idx > 0 ? '1em' : '',
            }}>{child}</div>
        ))}
    </div>;

export default Columns;
