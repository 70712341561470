import React from 'react';
import './emerald.css';

const EmeraldLessley = ({ small, centered = true }) => (
    <h1
        className="emerald-lessley"
        style={{
            fontSize: small ? '44px' : '88px',
            color: 'rgba(255, 75, 57, 1)',
            textTransform: 'uppercase',
            textAlign: centered ? 'center' : '',
        }}
    >
        Emerald Lessley
    </h1>
)

export default EmeraldLessley;
